/* eslint-disable react/jsx-no-bind */
import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo, useMemo } from 'react';
import { twJoin } from 'tailwind-merge';
import { isLightColor } from 'utils/helpers';
import { getDefaultColor, getDefaultCompetitionColor } from '../../../../helpers';
import { LineChartElementFormValues } from '../../dialogs/elementForms/interfaces';
import {
  MatchGeneralData,
  PlayerComparisonGeneralData,
  PlayerGeneralData,
  ScoutGeneralData,
  TeamComparisonGeneralData,
  TeamGeneralData
} from '../../reportCards/interfaces';
import { AttributeValues } from '../interfaces';
import { CompetitionChartPoint, LineChartPoint } from './interfaces';

interface LineChartInfoProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

const LineChartInfo = memo(function LineChartInfo({ report, element }: LineChartInfoProps) {
  const attributeValues = element.attribute_values as AttributeValues<LineChartElementFormValues>;
  const events: LineChartPoint[] = element.entity_data?.events ?? [];
  const type = attributeValues?.type?.[0] ?? 'For specific match';

  const EntityLegend = useMemo(() => {
    if (report.report_type === 'match') {
      const generalData = report.general_data as MatchGeneralData;
      return (
        <div className="flex flex-wrap items-center gap-6">
          <LineChartInfoCircle
            color={generalData.home_team_color ?? getDefaultColor(0)}
            name={generalData.home_team_name}
          />
          <LineChartInfoCircle
            color={generalData.away_team_color ?? getDefaultColor(1)}
            name={generalData.away_team_name}
          />
        </div>
      );
    } else if (type === 'For specific match') {
      return null;
    } else if (report.report_type === 'team') {
      const generalData = report.general_data as TeamGeneralData;
      return (
        <div className="flex flex-col gap-3">
          <LineChartInfoCircle color={generalData.color ?? getDefaultColor(0)} name={generalData.team_name} />
        </div>
      );
    } else if (report.report_type === 'player') {
      const generalData = report.general_data as PlayerGeneralData;

      return (
        <div className="flex flex-col gap-3">
          <LineChartInfoCircle color={generalData.team_color ?? getDefaultColor(0)} name={generalData.player_name} />
        </div>
      );
    } else if (report.report_type === 'team_comparison') {
      const { team1, team2 } = report.general_data as TeamComparisonGeneralData;
      return (
        <div className="flex flex-wrap items-center gap-6">
          <LineChartInfoCircle color={team1.color ?? getDefaultColor(0)} name={team1.team_name} />
          <LineChartInfoCircle color={team2.color ?? getDefaultColor(1)} name={team2.team_name} />
        </div>
      );
    } else if (report.report_type === 'player_comparison') {
      const { player1, player2 } = report.general_data as PlayerComparisonGeneralData;
      return (
        <div className="flex flex-wrap items-center gap-6">
          <LineChartInfoCircle color={player1.team_color ?? getDefaultColor(0)} name={player1.player_name} />
          <LineChartInfoCircle color={player2.team_color ?? getDefaultColor(0)} name={player2.player_name} />
        </div>
      );
    } else {
      const generalData = report.general_data as ScoutGeneralData;
      const allPlayers = [...generalData.players_manual, ...generalData.players_filtered];
      return (
        <div className="flex flex-wrap items-center gap-6">
          {allPlayers.map((player, i) => (
            <LineChartInfoCircle
              key={player.player_id}
              color={player.team_color ?? getDefaultColor(i)}
              name={player.player_name}
            />
          ))}
        </div>
      );
    }
  }, [report.report_type, report.general_data, type]);

  const CompetitonLegend = useMemo(() => {
    if (!element.entity_data?.competition_events) {
      return null;
    }
    const competitionEvents = element.entity_data.competition_events as CompetitionChartPoint[];
    const competitions = competitionEvents.reduce(
      (acc, event) => {
        if (!acc[event.competition_id]) {
          acc[event.competition_id] = event;
        }
        return acc;
      },
      {} as { [key: string]: CompetitionChartPoint }
    );
    const allCompetitions = Object.values(competitions);

    const timeScale = attributeValues?.time_scale?.[0] ?? 'By season';
    let period = '';
    switch (timeScale) {
      case 'By season':
        period = 'Season';
        break;
      case 'By match':
      case 'By match (last 10)':
        period = 'Match';
        break;
      case 'Match time':
        period = 'Match time';
        break;
    }
    let reportType = '';
    switch (report.report_type) {
      case 'team':
      case 'team_comparison':
        reportType = 'team';
        break;
      case 'player':
      case 'player_comparison':
      case 'scout':
        reportType = 'player';
        break;
    }

    return (
      <div className="flex flex-wrap items-center gap-6">
        {allCompetitions.map((competition, i) => (
          <LineChartInfoCircle
            key={competition.competition_id}
            color={getDefaultCompetitionColor(i)}
            name={`${competition.competition_name} - ${period} ${reportType} average`}
          />
        ))}
      </div>
    );
  }, [element.entity_data?.competition_events, report.report_type, element.attribute_values?.time_scale]);

  return (
    <div className="flex flex-col gap-2">
      {EntityLegend}
      {CompetitonLegend}
    </div>
  );
});

const LineChartInfoCircle = memo(function LineChartInfoCircle({ name, color }: { name: string; color: string }) {
  return (
    <div className="flex items-center gap-2">
      <span
        className={twJoin('size-3 rounded-full border', isLightColor(color) ? 'border-gray-400' : 'border-transparent')}
        style={{ backgroundColor: color }}
      />
      <span className="text-xs font-semibold">{name}</span>
    </div>
  );
});

export default LineChartInfo;
