import {
  MatchQuerySchema,
  PlayerQuerySchema,
  ProjectRoleSchema,
  ProjectRolesSchema,
  ProjectUserSchema,
  ReportElementTemplateSchema,
  SubPositionSchema,
  TeamQuery
} from 'lib/model';
import { EventMapEvent } from 'modules/reports/reportPage/components/dialogs/elementForms/interfaces';
import { conditionallyRender, getUserInitials, kebabCaseToWords, snakeCaseToWords } from './helpers';
import {
  AutocompleteOption,
  Competition,
  Match,
  MatchDetailed,
  Metric,
  MetricOption,
  Player,
  Season,
  UserRow,
  ValueOption
} from './interfaces';

/// /////////////////////
/// Value Option Mappings
/// /////////////////////

export function stringToValueOption(str: string): ValueOption {
  if (!str) return { label: 'NO DATA', id: '' };
  return {
    label: snakeCaseToWords(str),
    id: str
  };
}

export function playerToValueOption(player: Player): ValueOption {
  return {
    label: player.player_name,
    id: player.player_id
  };
}

export function subpositionToValueOption(sub_position: SubPositionSchema): ValueOption {
  return {
    id: `${sub_position.sub_position}`,
    label: `${sub_position.sub_position}`
  };
}

export function metricToMetricOption(metric: Metric): MetricOption {
  return {
    label: metric.type,
    id: metric.type,
    competition_ids_list: metric.competition_ids_list,
    primary_position_list: metric.primary_position_list,
    recommended_aggregations: metric.recommended_aggregations,
    seasons_list: metric.seasons_list
  };
}

export function eventToValueOption(event: EventMapEvent): ValueOption {
  if (event.event_type === 'Goal') {
    return {
      label: `${event.minutes_elapsed}' | ${event.player_name} | ${event.team_name}`,
      id: event.minutes_elapsed,
      value: event
    };
  }
  return {
    label: event.event_type,
    id: event.minutes_elapsed,
    value: event
  };
}

export function seasonToValueOption(season: Season): ValueOption {
  return {
    label: season.season,
    id: season.season
  };
}

export function competitionToValueOption(competition: Competition): ValueOption {
  return {
    label: competition.competition_name ?? '',
    id: competition.competition_id
  };
}

export function matchToValueOption(match: Match): ValueOption {
  return {
    label: `${match.home_team_name} vs ${match.away_team_name}${conditionallyRender(!!match.date, `| ${new Date(match.date).toLocaleDateString()}`)}`,
    id: match.match_id
  };
}

export function matchDetailedToValueOption(match: MatchDetailed): ValueOption {
  return {
    // Using == operator to check for nullish constraints while letting 0 through
    // eslint-disable-next-line eqeqeq
    label: `${match.home_team_name} - ${match.away_team_name}${conditionallyRender(!(match.home_team_score == null), `(${match.home_team_score}:${match.away_team_score})`)} | ${match.competition_name} | ${match.season} |${conditionallyRender(!!match.date, `${new Date(match.date).toLocaleDateString()}`)}`,
    id: match.match_id
  };
}

export function projectRoleToValueOption(projectRole: ProjectRoleSchema): ValueOption {
  return { id: projectRole.id!, label: snakeCaseToWords(projectRole.name!), value: projectRole.name };
}

export function elementTemplateToValueOption(template: ReportElementTemplateSchema): ValueOption {
  return {
    label: template.display_name!,
    id: template.id!
  };
}

export function matchTimeToValueOption(matchTime: number): ValueOption {
  return {
    label: `${matchTime}'`,
    id: matchTime
  };
}

/// /////////////////////
/// Autocomplete Option Mappings
/// /////////////////////

export function matchToAutocompleteOption(match: MatchQuerySchema): ValueOption {
  return {
    id: String(match.match_id),
    // Using == operator to check for nullish constraints while letting 0 through
    // eslint-disable-next-line eqeqeq
    label: `${match.home_team} - ${match.away_team}${conditionallyRender(!(match.home_team_score == null), `(${match.home_team_score}:${match.away_team_score})`)}`,
    secondaryLabel: `${conditionallyRender(!!match.date, `${new Date(match.date!).toLocaleDateString()}`)}${conditionallyRender(!!match.competition_name, `| ${match.competition_name}`)}`
  };
}

export function playerToAutocompleteOption(player: PlayerQuerySchema): ValueOption {
  return {
    id: player.player_id!,
    label: `${player.player_name}${conditionallyRender(!!player.shirt_number, player.shirt_number)}${conditionallyRender(!!player.team_name, `| ${player.team_name}`)}`,
    secondaryLabel: `${new Date(player.birth_date!).toLocaleDateString()}`
  };
}

export function teamToAutocompleteOption(team: TeamQuery): ValueOption {
  return {
    id: String(team.team_id),
    label: `${team.team_name}`,
    secondaryLabel: `${team.team_country}`
  };
}

export function competitionToAutocompleteOption(competition: Competition): ValueOption {
  return {
    id: competition.competition_id,
    label: competition.competition_name ?? 'NO NAME',
    secondaryLabel: competition.competition_country
  };
}

export function projectUserToOption(
  user: ProjectUserSchema,
  projectRoles: ProjectRolesSchema | undefined
): ValueOption {
  return {
    label: `${user.first_name} ${user.last_name}`,
    id: `${user.user_account_id}`,
    secondaryLabel: user.email,
    value: projectRoles?.objects!.find((x) => x.id === user.project_role_id)?.name
  };
}

/// /////////////////////
/// Utility Mappings
/// /////////////////////

export function projectUserToUserRow(projectUser: ProjectUserSchema, projectRoles: ProjectRoleSchema[]): UserRow {
  return {
    id: projectUser.user_account_id,
    name: (projectUser.first_name ?? '-') + ' ' + (projectUser.last_name ?? ''),
    email: projectUser.email,
    invite_sent: projectUser.invite_timestamp ? projectUser.invite_timestamp!.split('T')[0] : '-',
    access_level: snakeCaseToWords(projectRoles!.find((x) => x.id === projectUser.project_role_id)?.name ?? '-'),
    access_level_id: projectUser.project_role_id,
    initials: getUserInitials(projectUser),
    image: projectUser.user_image_path,
    last_login: projectUser.last_login ? projectUser.last_login!.split('T')[0] : '-'
  };
}
